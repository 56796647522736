.gHeader {
    margin-bottom: $sp-md;
    text-align: center;

    .masthead {
        padding: $sp-md $sp-sm;
    }

    .nav {
        padding: $sp-sm;
        background-color: white;
    }

    nav {
        ul {
            display: inline-flex;
            gap: $sp-md $sp-lg;
            flex-wrap: wrap;
            justify-content: center;
        }

        ul > li {
            display: inline-block;
        }
    }
}