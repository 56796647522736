.gSingle {
    .container {
        display: grid;
        grid-template-columns: 1fr 18rem;
        gap: $sp-lg;
        padding: $sp-md;

        @media (max-width: $bp-md) {
            grid-template-columns: auto;
        }
    }

    .hero {
        position: relative;
        margin-bottom: $sp-lg;
        margin-left: 15px;

        & > img {
            max-width: 100%; // Important to let img scale
            display: block;
            border-radius: 0.6em;
        }

        & > .decor {
            position: absolute;
            top: 15px;
            left: -15px;
            bottom: -15px;
            right: 15px;
            border: 2px solid #ffb129;
            z-index: -1;
            border-radius: 1em;
        }
    }

    .heroDecor::after {
        content: '';
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        border: 2px solid gray;
        left: 30px;
        top: -30px;
        z-index: -1;
    }

    .featuredBottom {
        display: none;
        margin: $sp-md;
    }

    @media (max-width: $bp-md) {
        .featuredSide {
            display: none;
        }

        .featuredBottom {
            display: block;
        }   
    }
}
