.gAuthors {
    display: flex;
    flex-direction: column;
    margin-bottom: 5em;
    padding-top: 1em;

    img {
        border-radius: 100%;
        align-self: center;
        max-width: max-content;
        margin-bottom: 1em;
    }

    h2 {
        text-align: center;
    }

    p {
        max-width: 38em;
        padding-left: 1em;
        padding-right: 1em;
        margin: 0 auto;
    }
}
