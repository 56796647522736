// Variables

$fs-base: 16px;
$fs-xs: 0.5rem;
$fs-sm: 0.75rem;
$fs-md: 1rem;
$fs-lg: 1.5rem;
$fs-xl: 1.75rem;

// External fonts
@import url('https://fonts.googleapis.com/css2?family=Londrina+Shadow&display=swap');

// Base Styles

html {
    font-size: $fs-base;
    font-family: system-ui, sans-serif;
}

h1 {
    font-size: $fs-xl;
}

h2 {
    font-size: $fs-lg;
}

h3 {
    font-size: $fs-md;
}

h1, h2, h3 {
    font-weight: bold;
    line-height: 1.6;
    color: $dark-gray;
    margin-bottom: $sp-sm;
}

.funky {
    font-size: 200%;
    font-family: 'Londrina Shadow';

    &.highlight {
        background: url('/highlight.svg') 0 50% no-repeat;
        background-size: 100% 85%;
        padding-left: 0.25em; // Add some space for start overflow
        padding-right: 0.25em; // Add some space for end overflow
        display: inline-block; // Keep it x-short, so we don't highligh empty space
    }
}

// Rule:
// h4, h5, h6: Do not use headers smaller than 3

p {
    line-height: 1.6;
    margin-bottom: $sp-md;
    color: $text-primary;
}

article {
    ul, ol {
        margin-bottom: $sp-md;
        margin-left: $sp-sm;
        list-style-position: inside;

        li {
            color: $dark-gray;
            line-height: 1.6;
        }
    }

    ul {
        list-style-type: disc;
    }

    ol {
        list-style: decimal;
        list-style-position: outside;
        margin-left: $sp-lg;
    }
}

a {
    color: #194400;
    text-decoration: none;

    &:hover {
        text-decoration: dotted underline;
    }
}

strong {
    font-weight: bold;
}
