.gFrontMatter {
    display: flex;
    margin: $sp-md 0;
    gap: $sp-sm;
    color: $text-primary;
    font-size: $fs-md;
    align-items: center;

    .photo {
        border-radius: 9999px;
        width: 24px;
        height: 24px;
    }

    & > span:not(:last-child) {
        &:after {
            content: '·';
            margin-inline-start: $sp-sm;
        }
    }
}
