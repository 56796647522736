.gList {
    margin-bottom: $sp-xl;
    text-align: center;
    
    .items {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: $sp-md;
        margin-right: $sp-md;
        margin-left: $sp-md;

        @media (max-width: $bp-md) {
            grid-template-columns: 1fr;
            gap: $sp-md;
        }
    }

    // This class comes from the Hugo internal pagination template.
    // The element is a <ul> contained in an unnamed <div>.
    .pagination {
        display: flex;
        gap: $sp-sm;
        justify-content: center;
        padding-top: $sp-md;
        padding-bottom: $sp-md;

        .page-item {
            padding: $sp-sm;
            border-radius: 0.5rem;
            
            &:not(.active,.disabled):hover {
                background-color: red;
                background-color: $light-gray;
            }

            &.active {
                border: 1px solid $light-gray;
            }
            
            &.disabled {
                color: $middle-gray;
            }
        }
    }
}
