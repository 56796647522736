.gFooter {
    padding: $sp-lg $sp-md $sp-xl $sp-md;
    border-top: 1px solid $light-gray;

    .container {
        display: grid;
        grid-template-columns: 1fr repeat(2, minmax(8rem, 18rem));
        gap:  $sp-md;
        text-align: end;

        @media (max-width: $bp-md) {
            grid-template-columns: unset;
            text-align: start;
        }

        .logo {
            text-align: start;
        }
    }

    .links {

        .heading {
            margin-bottom: $sp-sm;
            font-weight: bold;
            line-height: 1.6;
        }

        li {
            line-height: 2;
        }
    }
}