$thumbSize: 72px;

.gSummary {
    aspect-ratio: 16/10;
    text-align: left;
    border-radius: 1em;
    overflow: hidden;

    a {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: end;
    }

    .shade {
        padding: $sp-lg $sp-md $sp-md $sp-md;
        width: 100%;
        background: linear-gradient(#00000000, #000000A0);
        color: #ddd;
        text-shadow: 0 1px 1px #000;

        h2 {
            color: white;
            margin-bottom: $sp-sm;
            line-height: 1;
        }
    }

    &:hover, &:focus {
        // outline: 2px solid #ffb129;
    }

    time {
        display: block;
        margin-bottom: $sp-sm;
    }
}