$thumbSize: 72px;

.gRelated {
    
    .item {
        padding-bottom: $sp-md;
        margin-bottom: $sp-md;

        &:not(:last-child) {
            border-bottom: 1px solid #eee;
        }
    }

    .content {
        display: grid;
        grid-template-columns: $thumbSize 1fr;
        gap: $sp-md;
        margin-left: $sp-sm;
        line-height: 1.4;
        
        .thumb {
            position: relative;
            aspect-ratio: 1 / 1;
            width: $thumbSize;
            height: $thumbSize;

            .decor {
                position: absolute;
                left: -5px;
                top: 5px;
                right: 5px;
                bottom: -5px;
                border: 2px solid #ffb129;
                z-index: -1;
                border-radius: 0.5em;
            }

            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 0.35em;
            }
        }

        time {
            font-size: $fs-sm;
            color: #575757;
        }
    }
}
