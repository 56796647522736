.gAuthor {
    padding: $sp-md 0;
    background-color: #fffaee;
    border-top: 1px solid #ffefee;

    .container {
        display: grid;
        grid-template-columns: 80px 1fr;
        align-items: flex-start;
        gap: $sp-md;

        @media (max-width: $bp-md) {
            display: block;
            padding-top: 0;
            padding-bottom: 0;
            text-align: center;
        }
    }

    .name {
        margin-bottom: $sp-sm;

        @media (max-width: $bp-md) {
            margin-bottom: $sp-md;
        }

        .label {
            margin-inline-end: $sp-xs;
            font-size: $fs-sm;
            color: $text-primary;
        }

        .fn {
            font-weight: bold;
        }
    }

    .photo {
        grid-column-start: 1;
        grid-column-end: 1;
        width: 80px;
        height: 80px;
        border-radius: 100%;
    }

    .profile {
        grid-column-start: 2;
        grid-column-end: 2;
    }

    .bio {
        margin-bottom: 0;
        text-align: left;
    }
}
