.gIndex {
    text-align: center;

    .list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: $sp-md;
        margin-right: $sp-md;
        margin-left: $sp-md;
        margin-bottom: $sp-lg;

        @media (max-width: $bp-md) {
            grid-template-columns: 1fr;
            gap: $sp-md;
        }
    }
}
